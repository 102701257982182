import React, {useState} from 'react';
import * as style from './style.module.scss';
import axios from "axios";
import validator from "validator";
import image from '../../../../images/logo-transparent.png'
import { Link } from 'gatsby';
export default function SecondSubscribe() {

    const [email, setEmail] = useState("");
    const [success, setSuccess] = useState(false);
    const [emailError, setEmailError] = useState("");

    const validateEmail = (e) => {
        let email = e.target.value;
        setEmail(email);
        if (validator.isEmail(email)) {
            setEmailError(false);
        } else {
            setEmailError("Enter valid Email!");
        }
    };

    const handleSubmit = async () => {
        if (!emailError) {
            await axios({
                method: "post",
                url: "https://api.sendinblue.com/v3/contacts",
                data: {
                    updateEnabled: false,
                    email: email,
                    listIds: [2],
                    attributes: {tags: "Master WiZR"}
                },
                headers: {
                    "Content-Type": "application/json",
                    "api-key":
                        "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",
                },
            })
                .then(function (response) {
                    setSuccess(true);
                })
                .catch(function (response) {
                    setEmailError("Something went wrong!");
                });
        }
    };

    return (
        <div id="subscribe">
            <div className={style.secondSubscribe} id="secondSubscribe">
                <div className={style.subscribe}>
                    {emailError && (
                        <span style={{color: 'red', fontWeight: 500, fontSize: 20}}>{emailError}</span>
                    )}
                    {/* {!success && <div className={style.input}>
                        <input
                            type="email"
                            className={style.email}
                            id="email"
                            name="email"
                            placeholder='Your email'
                            value={email}
                            onChange={(e) => validateEmail(e)}
                        />
                        <input
                            type="submit"
                            value="Subscribe"
                            className={style.submit}
                            onClick={() => handleSubmit()}
                        />
                    </div>} */}
                    <h1 className={style.title}>Check out our Article Gallery</h1>
                    <Link to='/articles'>Gallery</Link>
                    {success && <div className={style.success}>
                        {/* <h2 className={style.success}><Correct/>Thanks for subscribing</h2> */}
                        <div className={style.subscription}>
                             <img src={image} width=
                             {100} alt="subscription"/>
                            <h1>Thanks for subscribing</h1>
                            </div>
                    </div>}
                </div>
            </div>
        </div>
    );
}
