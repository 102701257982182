// extracted by mini-css-extract-plugin
export var article = "headerBlog-module--article--26b42";
export var bgImage = "headerBlog-module--bgImage--03ddf";
export var containerBg = "headerBlog-module--containerBg--379d1";
export var containerBgInner = "headerBlog-module--containerBgInner--8600c";
export var containerDefault = "headerBlog-module--containerDefault--62fa5";
export var containerDefaultInner = "headerBlog-module--containerDefaultInner--63c25";
export var containerQuote = "headerBlog-module--containerQuote--eab42";
export var content = "headerBlog-module--content--45df9";
export var designContainer = "headerBlog-module--designContainer--af356";
export var image = "headerBlog-module--image--eb874";
export var logo = "headerBlog-module--logo--f8ad3";
export var magazine = "headerBlog-module--magazine--a6331";
export var tag = "headerBlog-module--tag--034ac";