// extracted by mini-css-extract-plugin
export var active = "body-module--active--a233d";
export var articleWP = "body-module--articleWP--14efd";
export var banner = "body-module--banner--90439";
export var body = "body-module--body--fe180";
export var bodyQuote = "body-module--bodyQuote--918c6";
export var bottomArticles = "body-module--bottomArticles--9403c";
export var caseStudies = "body-module--caseStudies--e30fa";
export var container = "body-module--container--d244f";
export var content = "body-module--content--19864";
export var data = "body-module--data--9259e";
export var edge = "body-module--edge--72961";
export var edge2 = "body-module--edge2--adf3a";
export var firstHeading = "body-module--firstHeading--4a3b7";
export var mainArticleContainer = "body-module--mainArticleContainer--e967a";
export var right = "body-module--right--32a40";
export var rightcard = "body-module--rightcard--c1525";
export var shareIcons = "body-module--shareIcons--197b0";
export var socials = "body-module--socials--48fa1";