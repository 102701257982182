import React, { useRef, useEffect } from "react";
import * as styles from "./headerBlog.module.scss";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import logo from "../../../../images/logoHolder.png"

const BlogHeader = ({
  type,
  title,
  image,
  date,
  categories,
  showBreadcrumbs,
  description,
  setShowShareSM,
}) => {

  const headerRef = useRef(null);
  const checkElement = () => {
    if (!headerRef) {
      return;
    }
    else {
      const monthPickerEl = document.getElementById("month-picks");
      const elementPosition = headerRef.current.getBoundingClientRect().top;
      const monthPickerPosition = monthPickerEl.getBoundingClientRect().top;
      if (elementPosition + headerRef.current.clientHeight - 80 < 0 && monthPickerPosition - 240 > 0) {
        setShowShareSM(true)
      } else {
        setShowShareSM(prev => {
          if (prev) {
            return false
          }
        })
      }
    }
  };

  useEffect(() => {
    document.addEventListener("scroll", checkElement);
    return () => {
      document.removeEventListener("scroll", checkElement);
    }
  }, [])

  const defaultView = () => {
    return (
      <div className={styles.containerDefault}>
        <div className={styles.containerDefaultInner}>
          {showBreadcrumbs && (
            <span className={styles.magazine}>
              Magazine | <span className={styles.article}>Article</span>
            </span>
          )}
          <h1>{title}</h1>
          <img alt={title} src={image} className={styles.image} />
        </div>
      </div>
    );
  };

  const bgView = () => {
    return (
      <div
        className={styles.containerBg}
        style={{ backgroundImage: `url(${image})` }}
      >
        <div
          className={styles.containerBgInner}>
          {showBreadcrumbs && (
            <span className={styles.magazine}>
              Magazine | <span className={styles.article}>Article</span>
            </span>
          )}
          <img className={styles.logo} src={logo} alt='MasterWizr' />
          <h1>{title}</h1>
          <div
            dangerouslySetInnerHTML={{ __html: description }}
            className={styles.description}
          ></div>
        </div>
      </div>
    );
  };
  const quoteView = () => {
    return (
      <div className={`${styles.containerDefault} ${styles.containerQuote} `}>
        <div className={styles.containerDefaultInner}>
          {/* {showBreadcrumbs && (
            <span className={styles.magazine}>
              Magazine | <span className={styles.article}>Article</span>
            </span>
          )} */}
          <h1>{title}</h1>
          <span className={styles.tag}>
            {date} <span> {categories[0].name}</span>
          </span>
          <img alt="MasterWizr" src={image} className={styles.bgImage} />
        </div>
      </div>
    );
  };

  const designView = () => {
    return (
      <div
        className={styles.designContainer}
      >
        <div className={styles.content}>
          <h1>{title}</h1>
          {/* <div
            dangerouslySetInnerHTML={{ __html: description }}
            className={styles.description}
          ></div> */}
          <p>to look into when you are in doubt</p>
        </div>
        <div className={styles.image} style={{ backgroundImage: `url(${image})` }} />
      </div>
    );
  };

  const renderView = (view) => {
    switch (view) {
      case "quote":
        return quoteView();
      case "background_image":
        return bgView();
      case "life_design":
        return designView();
      default:
        return defaultView();
    }
  };

  return <div ref={headerRef}>{renderView(type)}</div>;
};

export default BlogHeader;
