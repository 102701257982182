import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import Footer from "../../atoms/footer";
import Header from "../../atoms/header";
import BlogHeader from "./header";
import FreeTrial from "../../atoms/freeTrial";
import BlogBody from "./body";
import Head from "../../ogranisms/head";
import AOS from "aos";
import "aos/dist/aos.css";
const BlogPostTemplate = ({ data }) => {
  useEffect(() => {
    AOS.init({
      disable: "mobile",
      duration: 1000,
    });
  }, []);
  const [showShareSM, setShowShareSM] = useState(false)
  const type = data?.wpPost?.articleTemplate?.template[0];
  const { bannerDesc, bannerTitle, bottomArticles, breadcrumbs } =
    data?.wpPost?.articlesD;
  return (
    <>
      <Head
        image={
          data?.wpPost?.articlesD?.bannerImage?.sourceUrl
        }
        title={bannerTitle}
        description={data.wpPost.excerpt}
      />
      <main>
        <Header />
        <BlogHeader
          type={type}
          image={
            data?.wpPost?.articlesD?.bannerImage?.sourceUrl
          }
          title={bannerTitle}
          description={bannerDesc}
          date={data.wpPost.date}
          categories={data.wpPost.categories.nodes}
          showBreadcrumbs={breadcrumbs}
          setShowShareSM={setShowShareSM}
        />
        <BlogBody
          showShareSM={showShareSM}
          extraFileds={data.wpPost.articlesD}
          content={data.wpPost.content}
          type={type}
          category={data?.wpPost?.shortDescription?.articleCategory}
          tags={data?.wpPost?.articleTags?.tags}
        />
        <FreeTrial />
        <Footer />
      </main>
    </>
  );
};

export default BlogPostTemplate;
export const query = graphql`
  query ($id: String!) {
    wpPost(id: { eq: $id }) {
      title
      content
      excerpt
      date(formatString: "MMM DD, Y")
      articleTemplate {
        template
      }
      articleTags {
        tags
      }
      articlesD {
        bannerImage {
          node {
            sourceUrl
          }
        }
        showSubscribe
        articles
        bannerDesc
        bannerTitle
        bottomArticles
        instagramPosts
        breadcrumbs
      }
      shortDescription {
        shortDescription
        shortText
        articleCategory
      }

      featuredImage {
        node {
          sourceUrl
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      tags {
        nodes {
          name
        }
      }
      categories {
        nodes {
          name
        }
      }
    }
  }
`;
