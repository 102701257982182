import React, { useEffect, useRef, useState } from "react";
import * as styles from "./body.module.scss";
import SecondSubscribe from "../../../molecules/Magazine/SecondSubscribe";
import InstaSlider from "../../../molecules/Magazine/instaSlider";
import { graphql, useStaticQuery } from "gatsby";
import WhiteCard from "../../../molecules/WhiteCard/WhiteCard";
import { FaFacebookF, FaTwitter, FaLinkedinIn } from "react-icons/fa";
import image from "../../../../images/rightimage.jpg";
import leftimage from "../../../../images/leftarticle.jpg";
const BlogBody = ({ content, type, extraFileds, tags, showShareSM }) => {
  const [, setState] = useState(false);
  const [, setIsFixed] = useState(false);
  const containerRef = useRef(null);
  const { allWpPost } = useStaticQuery(
    graphql`
      query {
        allWpPost {
          edges {
            node {
              articleTags {
                tags
              }
              articlesD {
                bannerImage {
                  node {
                    sourceUrl
                  }
                }
                monthPick
              }
              shortDescription {
                shortDescription
                shortText
                articleCategory
              }
              slug
              title
            }
          }
        }
      }
    `
  );
  const [, setOffset] = useState(0);
  useEffect(() => {
    window.innerHeight > 1200 ? setState(true) : setState(false);
  }, [setState]);
  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const handleFacebookShare = () => {
    if(typeof window !== "undefined") {
      window.open(
          `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`,
          "facebook-share-dialog",
          "top=${(screen.height - 570)/2}, left=${(screen.width - 570)/2}, width=700, height=400"
      );
    }
  };

  const handleLinkedInShare = () => {
    if(typeof window !== "undefined"){
      window.open(
        `https://www.linkedin.com/shareArticle?mini=true&url=${window.location.href}`,
        "linkedin-share-dialog",
        "top=${(screen.height - 570)/2}, left=${(screen.width - 570)/2}, width=700, height=400"
      );
    }
  };

  const handleTwitterShare = () => {
    if(typeof window !== "undefined"){
      window.open(
          `https://twitter.com/share?url=${window.location.href}`,
          "instagram-share-dialog",
          "top=${(screen.height - 570)/2}, left=${(screen.width - 570)/2}, width=700, height=400"
      );
    }
  };
  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current) {
        setIsFixed(window.scrollY >= containerRef.current.offsetTop);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className={`${styles.body} ${type === "quote" && styles.bodyQuote}`}>
        <ul
          className={
            showShareSM
              ? `${styles.shareIcons} ${styles.active}`
              : `${styles.shareIcons}`
          }
        >
          <li onClick={() => handleFacebookShare()}>
            <FaFacebookF />
          </li>
          <li onClick={() => handleTwitterShare()}>
            <FaTwitter />
          </li>
          <li onClick={() => handleLinkedInShare()}>
            <FaLinkedinIn />
          </li>
        </ul>
        <div ref={containerRef} className={styles.mainArticleContainer}>
          {tags?.includes("parallax") ? (
            ""
          ) : (
            <>
              <div className={styles.edge}>
                <img src={leftimage} alt="Black and white portrait of a woman with studio lighting, looking to the side."/>
                <h1 className={styles.firstHeading}>
                  <br />{" "}
                  <span>
                    Express <br /> Yourself
                  </span>
                </h1>
              </div>
            </>
          )}
          <div
            className={styles.content}
            dangerouslySetInnerHTML={{ __html: content }}
            style={type == "quote" ? { paddingTop: "17rem" } : {}}
          ></div>
          {tags?.includes("parallax") ? (
            ""
          ) : (
            <div className={`${styles.edge} ${styles.edge2}`}>
              <img src={image} alt="Man looking up with a dark background, black and white image, studio light illuminating his face."/>
              <h1>
                <br />{" "}
                <span>
                  Shine <br /> Professionally
                </span>
              </h1>
            </div>
          )}
        </div>
        <div id="month-picks" className={`${styles.container}`}>
          <h1>This month's picks</h1>
          <div className={styles.data}>
            {allWpPost?.edges
              .filter((post) => post?.node?.articlesD?.monthPick === true)
              .map((post) => {
                return (
                  <WhiteCard
                    extraClass={"articleWP"}
                    desc={post?.node?.shortDescription?.shortText}
                    image={post?.node?.articlesD?.bannerImage?.sourceUrl}
                    title={post?.node?.title}
                    link={`/blog/${post?.node?.slug}`}
                  />
                );
              })}
          </div>
        </div>
        <div className={styles.subscribe}>
          {extraFileds.showSubscribe && <SecondSubscribe />}
          {extraFileds.instagramPosts && <InstaSlider />}
        </div>
      </div>
    </>
  );
};

export default BlogBody;
